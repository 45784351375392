import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import "./common/scss/app.scss";
import { Provider } from "react-redux";
import { store } from "./common/redux/store";
import ScrollToTop from "./common/components/ScrollToTop";
import AppWrapper from "./common/components/AppWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    // Skip redirect for terms and privacy pages
    const currentPath = window.location.pathname;
    if (currentPath === '/user-terms-and-conditions' || currentPath === '/privacy-policy') {
      return;
    }

    const currentDomain = window.location.hostname;
    if (currentDomain === 'taisk.com') {
      window.location.replace('https://app.taisk.com');
    // } else if (currentDomain === 'marketplace-staging.taisk.com') {
    } else if (currentDomain === 'localhost') {
      window.location.replace('https://app-staging.taisk.com');
    }
  }, []);

  // If we're not redirecting, render the actual app
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <AppWrapper>
            <ScrollToTop />
            <AppRoutes />
          </AppWrapper>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
